import QrCodeWithLogo from "qr-code-with-logo";
import html2canvas from "html2canvas";
import Logo from '@/assets/qrcode.png';
import JSZip from 'jszip'
// @ts-ignore
import FileSaver from 'file-saver'
import moment from 'moment'

async function getSourceList(rows: any) {
  const all = await Promise.allSettled(rows.map(async (row: any, index: any) => {
    const { content, title, subTitle } = row
    return {
      id: index,
      qrCode: await getVirturCanvas(content, title, subTitle),
      name: `${title}-${subTitle}`
    }
  }))
  // @ts-ignore
  return all?.map((child) => child?.value)
}

function getVirturCanvas(content = "", title = "", subTitle = "") {
  return new Promise((resolve, reject) => {
    const uuidContainer = Math.floor(Math.random() * 10000) + new Date().getTime() + 'container'
    const uuidQrcode = Math.floor(Math.random() * 10000) + new Date().getTime() + 'qrcode'
    const virturContainer = document.createElement('div')
    const qrcodeDom = document.createElement("div");
    const titleDom = document.createElement('div')
    const subTitleDom = document.createElement('div')
    qrcodeDom.setAttribute('id', uuidQrcode)
    virturContainer.style.width = '380px'
    virturContainer.style.height = '450px'
    virturContainer.style.position = 'fixed'
    titleDom.style.marginLeft = '36px'
    titleDom.style.fontSize = '14px'
    titleDom.style.fontWeight = 'blod'
    subTitleDom.style.fontSize = '12px'
    subTitleDom.style.marginLeft = '36px'
    titleDom.innerText = title
    subTitleDom.innerText = subTitle
    virturContainer.setAttribute('id', uuidContainer)
    virturContainer.appendChild(qrcodeDom)
    virturContainer.appendChild(titleDom)
    virturContainer.appendChild(subTitleDom)
    document.body.appendChild(virturContainer)
    const myCanvas = document.createElement("canvas");
    // @ts-ignore
    document.getElementById(uuidQrcode).appendChild(myCanvas);
    QrCodeWithLogo.toCanvas({
      canvas: myCanvas,
      content,
      width: 380,
      logo: {
        src: Logo,
        // @ts-ignore
        radius: 8,
      },
    });
    setTimeout(async () => {
      try {
        const img = await convertToImage(document.getElementById(uuidContainer))
        setTimeout(() => {
          document.body.removeChild(virturContainer)
        }, 500)
        resolve(img)
      } catch (error) {
        reject(error)
      }
    }, 500)
  })

}

async function downloadZip(rows: any, name = 'IIMS批量导出二维码') {
  const zipName = name + moment().format('YYYYMMDDHHmmss')
  const sourceList = await getSourceList(rows)
  const zip = new JSZip()
  const fileFolder = zip.folder(zipName) // 创建 zipName 文件夹
  const fileList: any = []
  for (let i = 0; i < sourceList.length; i++) {
    const name = sourceList[i]?.name // 注意: 每张图片的名称--需要对数据属性进行转换
    const url = sourceList[i]?.qrCode
    fileList.push({ name: name, img: url.substring(22) }) // 截取 data:image/png;base64, 后的数据
    if (fileList.length === sourceList.length) {
      if (fileList.length) {
        for (let k = 0; k < fileList.length; k++) {
          // 往文件夹中，添加每张图片数据
          // @ts-ignore
          fileFolder.file(fileList[k].name + '.png', fileList[k].img, {
            base64: true
          })
        }
        zip.generateAsync({ type: 'blob' }).then(content => {
          FileSaver.saveAs(content, zipName + '.zip')
        })
      }
    }
  }
}

// 生成快照
function convertToImage(container: any, options = {}) {
  // 设置放大倍数
  const scale = window.devicePixelRatio;

  // 传入节点原始宽高
  const _width = container.offsetWidth;
  const _height = container.offsetHeight;

  // @ts-ignore
  let { width, height } = options;
  width = width || _width;
  height = height || _height;

  // html2canvas配置项
  const ops = {
    scale,
    // width,
    // height,
    useCORS: true,
    allowTaint: false,
    ...options
  };

  return html2canvas(container, ops).then(canvas => {
    // 返回图片的二进制数据
    return canvas.toDataURL("image/png");
  });
}
async function saveQrcode(name = "IIMS二维码") {
  const imgBlobData = await convertToImage(document.getElementById('iims-content'));
  // 创建新的URL并指向File对象或者Blob对象的地址
  // 创建a标签，用于跳转至下载链接
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = imgBlobData;
  tempLink.setAttribute("download", decodeURI(name));
  // 挂载a标签
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}
function onPreviewQrcode($v: any, content: string, title: string, subTitle: string) {
  const h = $v.$createElement;
  $v.$alert("", "", {
    showConfirmButton: false,
    closeOnClickModal: true,
    message: h("div", {
      attrs: { id: "iims-qrcode-container" }
    },
      [
        h("div", { attrs: { id: "iims-content" } }, [
          h("div", { attrs: { id: "qrcode" } },),
          h("div", { attrs: { id: "iims-qrcode-title" } }, title),
          h("div", { attrs: { id: "iims-qrcode-sub-title" } }, subTitle),
        ]),
        h("div", { attrs: { id: "iims-qrcode-download" }, on: { click: () => saveQrcode(`${title}-${subTitle}`) } }, '点我保存二维码'),
      ]
    ),
  })
    .then(() => {
      const qrcodeNode = document.getElementById("qrcode")
      // @ts-ignore
      qrcodeNode.innerHTML = ''
    })
    .catch(() => {
      const qrcodeNode = document.getElementById("qrcode")
      // @ts-ignore
      qrcodeNode.innerHTML = ''
    });
  $v.$nextTick(() => {
    const myCanvas = document.createElement("canvas");
    // @ts-ignore
    document.getElementById("qrcode").appendChild(myCanvas);
    QrCodeWithLogo.toCanvas({
      canvas: myCanvas,
      content,
      width: 380,
      logo: {
        src: Logo,
        // @ts-ignore
        radius: 8,
      },
    });
  })
}

export {
  onPreviewQrcode,
  downloadZip,
}
